import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import moment from 'moment';
import { ActivityIndicator, Card, WhiteSpace, Button } from 'antd-mobile';
import { Select } from 'antd';

import buyersPendingDeliveryVerificationQuery from '../graphql/queries/buyersPendingDeliveryVerificationQuery.graphql';
import unverifiedSellerShipmentsByBuyerIdQuery from '../graphql/queries/unverifiedSellerShipmentsByBuyerIdQuery.graphql';

class ToBeVerifiedShipmentsList extends Component {
  state = {
    buyersSelectValue: undefined,
  };

  onPickerOk = (value) => {
    this.setState({ buyersSelectValue: value });
  };

  render() {
    const { buyersSelectValue } = this.state;
    const { navigate } = this.props;
    return (
      <div className="contentBox">
        <h2 style={{ padding: '10px', textAlign: 'center' }}>Verify Shipment</h2>
        <Query query={buyersPendingDeliveryVerificationQuery} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) return <ActivityIndicator />;
            if (error) return <p>{error.message}</p>;
            const { buyers } = data;
            return (
              <div style={{ padding: 10 }}>
                <h3 style={{ marginLeft: 3 }}>Merchant:</h3>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select merchant"
                  onChange={this.onPickerOk}
                  optionFilterProp="children"
                >
                  {buyers.map((entry) => (
                    <Select.Option value={entry.id} key={entry.id}>
                      {entry.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            );
          }}
        </Query>
        {buyersSelectValue ? (
          <Query
            variables={{ buyerId: buyersSelectValue }}
            query={unverifiedSellerShipmentsByBuyerIdQuery}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if (loading) return <ActivityIndicator />;
              if (error) return <p>{error.message}</p>;
              const { shipments } = data;
              return (
                <div style={{ padding: 15 }}>
                  <h3 style={{ textAlign: 'center' }}>Shipments Delivered</h3>
                  {shipments.map((entry) => {
                    const productsArr = [];
                    entry.orders.forEach((entry1) => {
                      productsArr.push(entry1.product.name);
                    });
                    return (
                      <Fragment key={entry.id}>
                        <WhiteSpace size="lg" />
                        <Card>
                          <Card.Header title={entry.seller.name} />
                          <Card.Body>
                            <p style={{ marginBottom: 0 }}>
                              <strong>Product/Brand: </strong>
                              {productsArr.join(', ')}
                            </p>
                            <p style={{ marginBottom: 0 }}>
                              <strong>Dispatch Date: </strong>
                              {moment(entry.dispatchDate, 'YYYY-MM-DD').format('Do MMM')}
                            </p>
                            <p style={{ marginBottom: 0 }}>
                              <strong>Transport: </strong>
                              {entry.transport}
                            </p>
                            <p style={{ marginBottom: 0 }}>
                              <strong>Vehicle No.: </strong>
                              {entry.vehicleNo}
                            </p>
                            <p>
                              <strong>Invoice No.: </strong>
                              {entry.invoiceNo}
                            </p>
                            <Button size="small" type="primary" onClick={() => navigate(`${entry.id}`)}>
                              Verify Shipment
                            </Button>
                          </Card.Body>
                        </Card>
                      </Fragment>
                    );
                  })}
                </div>
              );
            }}
          </Query>
        ) : null}
      </div>
    );
  }
}

ToBeVerifiedShipmentsList.propTypes = {
  navigate: PropTypes.func,
};

ToBeVerifiedShipmentsList.defaultProps = {
  navigate: null,
};

export default ToBeVerifiedShipmentsList;
