import React from 'react';
import { Router } from '@reach/router';

import { loginPagePath } from './uitls/globals';

import LoginPage from './pages/LoginPage';
import App from './layouts/AppLayout';
import UpdatePasswordPage from './pages/UpdatePasswordPage';
import HomePage from './pages/HomePage';
import DailyRatesProduct from './pages/DailyRatesProduct';
import BuyerOrdersRequirements from './pages/BuyerOrdersRequirements';
import ToBeVerifiedShipmentsList from './pages/ToBeVerifiedShipmentsList';
import VerifyShipmentPage from './pages/VerifyShipmentPage';
import PaymentCollectionPage from './pages/PaymentCollectionPage';
import ShipmentPaymentCollectionPage from './pages/ShipmentPaymentCollectionPage';

const Routes = () => (
  <Router>
    <LoginPage path={loginPagePath} />
    <App path="/">
      <UpdatePasswordPage path="auth/password/update" />
      <DailyRatesProduct path="/rates/products" />
      <BuyerOrdersRequirements path="/orders/buyers" />
      <ToBeVerifiedShipmentsList path="/verification/shipments" />
      <VerifyShipmentPage path="/verification/shipments/:id" />
      <PaymentCollectionPage path="/buyers/payments/create" />
      <ShipmentPaymentCollectionPage path="/shipment/:id/payment/create" />
      {/* Catch all */}
      <HomePage path="/*" />
    </App>
  </Router>
);

export default Routes;
