import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd-mobile';
import { navigate } from '@reach/router';

const ListItem = List.Item;

class AppSidebar extends Component {
  goHome = () => {
    const { closeAppSidebarDrawer } = this.props;
    navigate('/');
    closeAppSidebarDrawer();
  };

  handleLogout = () => {
    const { closeAppSidebarDrawer, logout } = this.props;
    logout();
    closeAppSidebarDrawer();
  };

  handleUpdatePasswordClick = () => {
    const { closeAppSidebarDrawer } = this.props;
    navigate('/auth/password/update');
    closeAppSidebarDrawer();
  };

  render() {
    const { name } = this.props;
    return (
      <div id="appSidebar">
        <h2 style={{ textAlign: 'center', padding: '20px', margin: 0 }}>{name}</h2>
        <List>
          <ListItem onClick={this.goHome}>Home</ListItem>
          <ListItem onClick={this.handleLogout}>Logout</ListItem>
          <ListItem onClick={this.handleUpdatePasswordClick}>Update Password</ListItem>
        </List>
      </div>
    );
  }
}

AppSidebar.propTypes = {
  name: PropTypes.string.isRequired,
  closeAppSidebarDrawer: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default AppSidebar;
