import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, Modal, Button, Toast } from 'antd-mobile';
import { Select } from 'antd';
import { graphql, compose } from 'react-apollo';
import { getCurrencyFormattedValue, log } from '../uitls/globals';
import DailyProductRateForm from '../forms/DailyProductRateForm';

import getProductsWithRatesQuery from '../graphql/queries/getProductsWithRatesQuery.graphql';
import sendRateSmsMutation from '../graphql/mutations/sendRateSmsMutation.graphql';

class DailyRatesProduct extends Component {
  state = {
    productSelected: undefined,
  };

  onProductSelect = (value) => {
    this.setState({ productSelected: value });
  };

  onMutationSuccess = () => {
    this.setState({ productSelected: undefined });
  };

  render() {
    const { productSelected } = this.state;
    const { loading, error, refetch } = this.props.data;
    if (loading) return <ActivityIndicator />;
    if (error) return <p>{error.message}</p>;
    const { products } = this.props.data;
    let productSelectedEntry = null;
    if (productSelected) productSelectedEntry = products.find((entry) => entry.id === productSelected);
    return (
      <div>
        <h2 style={{ textAlign: 'center', padding: 10, marginTop: 10 }}>Product Rate</h2>
        <Button
          type="primary"
          style={{ margin: '0 15px 15px' }}
          onClick={() => {
            Modal.alert('Send SMS', 'Are you sure?', [
              {
                text: 'Yes',
                onPress: () => {
                  this.props
                    .mutate()
                    .then(() => {
                      Toast.success('SMS Sent!', 1);
                    })
                    .catch((mutErr) => {
                      log(mutErr);
                      Toast.fail(mutErr.message, 3);
                    });
                },
              },
              { text: 'No' },
            ]);
          }}
        >
          Send rate SMS to merchants
        </Button>
        <div className="contentBoxWithPadding">
          <h3 style={{ marginLeft: 3 }}>Select Product/Brand:</h3>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select product"
            onChange={this.onProductSelect}
            value={productSelected}
            optionFilterProp="children"
          >
            {products.map((entry) => (
              <Select.Option value={entry.id} key={entry.id}>
                {entry.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        {productSelected ? (
          <div className="contentBoxWithPadding">
            <p>
              <strong>Product / Brand: </strong>
              {productSelectedEntry.name}
            </p>
            <p>
              <strong>Miller: </strong>
              {productSelectedEntry.seller.name}
            </p>
            <p>
              <strong>Last Rate: </strong>
              {Array.isArray(productSelectedEntry.dailyRates) && productSelectedEntry.dailyRates.length > 0
                ? getCurrencyFormattedValue(productSelectedEntry.dailyRates[0].ratePerQuintal)
                : 'N/A'}
            </p>
            <h2 style={{ textAlign: 'center' }}>Enter New Rate</h2>
            <DailyProductRateForm
              productId={productSelected}
              key={productSelected}
              onMutationSuccess={this.onMutationSuccess}
              refetchQuery={refetch}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

DailyRatesProduct.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any,
    refetch: PropTypes.func,
    products: PropTypes.array,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default compose(
  graphql(sendRateSmsMutation),
  graphql(getProductsWithRatesQuery, { options: { fetchPolicy: 'network-only' } }),
)(DailyRatesProduct);
