import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavBar, Drawer } from 'antd-mobile';
import withAuth from '../auth/withAuth';
import AppSidebar from '../components/AppSidebar';

class AppPage extends Component {
  state = {
    appSidebarDrawerOpen: false,
  };

  toggleAppSidebarDrawer = () => {
    const { appSidebarDrawerOpen } = this.state;
    this.setState({ appSidebarDrawerOpen: !appSidebarDrawerOpen });
  };

  render() {
    const { user, children, logout } = this.props;
    const { appSidebarDrawerOpen } = this.state;
    return (
      <Drawer
        sidebar={<AppSidebar name={user.name} closeAppSidebarDrawer={this.toggleAppSidebarDrawer} logout={logout} />}
        open={appSidebarDrawerOpen}
        className="appSidebarDrawer"
        onOpenChange={this.toggleAppSidebarDrawer}
      >
        <NavBar icon={<i className="material-icons">menu</i>} onLeftClick={this.toggleAppSidebarDrawer}>
          Tejas Canvassing
        </NavBar>
        {children}
      </Drawer>
    );
  }
}

AppPage.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default withAuth(true)(AppPage);
