import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber } from 'antd';
import { graphql, compose } from 'react-apollo';
import { Button, Toast } from 'antd-mobile';
import { log } from '../uitls/globals';

// import STORE_QUERY from '../graphql/queries/getProductsWithRatesQuery.graphql';
import MUTATION from '../graphql/mutations/createDailyRateMutation.graphql';

const FormItem = Form.Item;

class DailyProductRateForm extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submittingForm: true });

    const { form, mutate, productId, onMutationSuccess, refetchQuery } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        mutate({
          variables: { productId, ...values },
          // update: (proxyStore, { data: { dailyRate } }) => {
          //   const data = proxyStore.readQuery({ query: STORE_QUERY });
          //   data.products = data.products.map((entry) => {
          //     if (entry.id === productId) {
          //       if (Array.isArray(entry.dailyRates)) return { ...entry, dailyRates: [dailyRate, ...entry.dailyRates] };
          //       return { ...entry, dailyRates: [dailyRate] };
          //     }
          //     return entry;
          //   });
          //   proxyStore.writeQuery({ query: STORE_QUERY, data });
          // },
        })
          .then(() => {
            this.setState({ submittingForm: false });
            Toast.success('Rate noted!', 1);
            onMutationSuccess();
            refetchQuery();
          })
          .catch((mutErr) => {
            log(mutErr);
            this.setState({ submittingForm: false });
            Toast.fail(mutErr.message, 3);
          });
      } else {
        this.setState({ submittingForm: false });
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { submittingForm } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem label="Rate (per quintal)" hasFeedback>
          {getFieldDecorator('ratePerQuintal', {
            rules: [{ required: true, message: 'Field is required' }],
          })(<InputNumber disabled={submittingForm} required />)}
        </FormItem>
        <FormItem label="Minimum Order Quantity (quintals)">
          {getFieldDecorator('minQuintals')(<InputNumber disabled={submittingForm} />)}
        </FormItem>
        <FormItem label="Maximum Order Quantity (quintals)">
          {getFieldDecorator('maxQuintals')(<InputNumber disabled={submittingForm} />)}
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={this.handleSubmit} loading={submittingForm}>
            Submit
          </Button>
        </FormItem>
      </Form>
    );
  }
}

DailyProductRateForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  onMutationSuccess: PropTypes.func.isRequired,
  refetchQuery: PropTypes.func.isRequired,
};

export default compose(
  graphql(MUTATION),
  Form.create(),
)(DailyProductRateForm);
