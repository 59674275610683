import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';
import { ActivityIndicator, Button } from 'antd-mobile';
import { Select } from 'antd';
import { navigate } from '@reach/router';

import buyersPendingShipmentPaymentQuery from '../graphql/queries/buyersPendingShipmentPaymentQuery.graphql';
import unpaidSellerShipmentsByBuyerIdQuery from '../graphql/queries/unpaidSellerShipmentsByBuyerIdQuery.graphql';

class PaymentCollectionPage extends Component {
  state = {
    buyersSelectValue: undefined,
  };

  onChangeBuyerSelect = (value) => {
    this.setState({ buyersSelectValue: value });
  };

  render() {
    const { buyersSelectValue } = this.state;
    return (
      <div className="contentBox">
        <h2 style={{ padding: '10px', textAlign: 'center' }}>Buyer Payment Collection</h2>
        <Query query={buyersPendingShipmentPaymentQuery} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) return <ActivityIndicator />;
            if (error) return <p>{error.message}</p>;
            const { buyers } = data;
            return (
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select merchant"
                onChange={this.onChangeBuyerSelect}
                optionFilterProp="children"
              >
                {buyers.map((entry) => (
                  <Select.Option value={entry.id} key={entry.id}>
                    {entry.name}
                  </Select.Option>
                ))}
              </Select>
            );
          }}
        </Query>

        {/* Seller shipments unpaid */}
        {buyersSelectValue ? (
          <Query
            variables={{ buyerId: buyersSelectValue }}
            query={unpaidSellerShipmentsByBuyerIdQuery}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if (loading) return <ActivityIndicator />;
              if (error) return <p>{error.message}</p>;
              const { shipments } = data;
              return (
                <Fragment>
                  {shipments.map((entry) => (
                    <div key={entry.id} style={{ margin: '15px 10px', border: '1px solid #DDDDDD', padding: 10 }}>
                      <p>
                        <strong>Miller.: </strong>
                        {entry.seller.name}
                      </p>
                      <p>
                        <strong>Invoice No.: </strong>
                        {entry.invoiceNo}
                      </p>
                      <p>
                        <strong>Dispatch Date: </strong>
                        {moment(entry.dispatchDate, 'YYYY-MM-DD').format('Do MMM')}
                      </p>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => navigate(`/shipment/${entry.id}/payment/create`)}
                      >
                        Collect Payment
                      </Button>
                    </div>
                  ))}
                </Fragment>
              );
            }}
          </Query>
        ) : null}
      </div>
    );
  }
}

export default PaymentCollectionPage;
