import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Query, graphql, compose } from 'react-apollo';
import { Form, InputNumber, Input } from 'antd';
import { ActivityIndicator, List, Card, WhiteSpace, Button, DatePicker, LocaleProvider, Toast } from 'antd-mobile';
import enUS from 'antd-mobile/lib/locale-provider/en_US';
import moment from 'moment';
import { log, getCurrencyFormattedValue } from '../uitls/globals';

import shipmentToVerifyByIdQuery from '../graphql/queries/shipmentToVerifyByIdQuery.graphql';
import MUTATION from '../graphql/mutations/verifyShipmentMutation.graphql';

import './VerifyShipmentPage.scss';

class VerifyShipmentPage extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submittingForm: true });
    const { form, id, mutate } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const valuesToSubmit = { id: parseInt(id, 10), ...values };
        valuesToSubmit.deliveryDate = moment(valuesToSubmit.deliveryDate).format('YYYY-MM-DD');
        valuesToSubmit.orders = Object.values(valuesToSubmit.orders);
        mutate({ variables: valuesToSubmit })
          .then(() => {
            Toast.success('Shipment verified', 1);
            this.setState({ submittingForm: false });
            window.history.back();
          })
          .catch((mutErr) => {
            log(mutErr);
            this.setState({ submittingForm: false });
            Toast.fail(mutErr.message, 3);
          });
      } else {
        if (err.deliveryDate) Toast.fail('Select delivery date', 2);
        this.setState({ submittingForm: false });
      }
    });
  };

  render() {
    const { id, form } = this.props;
    const { submittingForm } = this.state;
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <LocaleProvider locale={enUS}>
        <div className="contentBoxWithPadding">
          <h2 style={{ padding: '10px', textAlign: 'center' }}>Verify Shipment</h2>
          <Query variables={{ id: parseInt(id, 10) }} query={shipmentToVerifyByIdQuery} fetchPolicy="network-only">
            {({ loading, error, data }) => {
              if (loading) return <ActivityIndicator />;
              if (error)
                return (
                  <p>
                    <strong>ERROR: </strong>
                    {error.message}
                  </p>
                );
              const { shipment } = data;

              let totalAmount = 0;
              let totalQuantity = 0;
              const ordersFormData = getFieldValue('orders');
              shipment.orders.forEach((entry) => {
                if (ordersFormData) {
                  const ofd = ordersFormData[entry.id];
                  const orderFinalQty = ofd.quantityDeliveredQuintals - ofd.quantityRejectedQuintals;
                  totalQuantity += orderFinalQty;
                  totalAmount += orderFinalQty * (entry.invoicedRatePerQuintal - ofd.qualityDiRatePerQuintal);
                } else {
                  totalQuantity += entry.quantityShippedQuintals;
                  totalAmount += entry.quantityShippedQuintals * entry.invoicedRatePerQuintal;
                }
              });

              return (
                <Fragment>
                  <p style={{ marginBottom: 0 }}>
                    <strong>Buyer: </strong>
                    {shipment.buyer.name}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    <strong>Seller: </strong>
                    {shipment.seller.name}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    <strong>Dispatch Date: </strong>
                    {moment(shipment.dispatchDate, 'YYYY-MM-DD').format('Do MMM')}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    <strong>Transport: </strong>
                    {shipment.transport}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    <strong>Vehicle No.: </strong>
                    {shipment.vehicleNo}
                  </p>
                  <p>
                    <strong>Invoice No.: </strong>
                    {shipment.invoiceNo}
                  </p>
                  <Form>
                    <List>
                      {getFieldDecorator('deliveryDate', { rules: [{ required: true, message: 'Field is required' }] })(
                        <DatePicker
                          mode="date"
                          disabled={submittingForm}
                          format={(date) => moment(date).format('Do MMM')}
                        >
                          <List.Item className="selectPickerListItem" arrow="down">
                            Delivery Date
                          </List.Item>
                        </DatePicker>,
                      )}
                    </List>
                    <h3 style={{ margin: '15px 0', textAlign: 'center' }}>Orders delivered</h3>
                    {shipment.orders.map((entry) => (
                      <Fragment key={entry.id}>
                        <Card full={false}>
                          <Card.Header title={entry.product.name} />
                          <Card.Body>
                            <p>
                              <strong>Quantity shipped: </strong>
                              {entry.quantityShippedQuintals} qtls
                            </p>
                            <p>
                              <strong>Rate (per qtl): </strong>
                              {getCurrencyFormattedValue(entry.invoicedRatePerQuintal)}
                            </p>
                            {getFieldDecorator(`orders.${entry.id}.id`, { initialValue: entry.id })(
                              <input type="hidden" />,
                            )}
                            <Form.Item label="Quantity Delivered (qtls)" hasFeedback>
                              {getFieldDecorator(`orders.${entry.id}.quantityDeliveredQuintals`, {
                                initialValue: entry.quantityShippedQuintals,
                                rules: [{ required: true, message: 'Field is required' }],
                              })(<InputNumber disabled={submittingForm} />)}
                            </Form.Item>
                            <Form.Item label="Quality DI Rate (per qtl)">
                              {getFieldDecorator(`orders.${entry.id}.qualityDiRatePerQuintal`, { initialValue: 0 })(
                                <InputNumber disabled={submittingForm} />,
                              )}
                            </Form.Item>
                            <Form.Item label="Reason for quality DI">
                              {getFieldDecorator(`orders.${entry.id}.qualityDiReason`)(
                                <Input.TextArea autosize={{ minRows: 2, maxRows: 5 }} disabled={submittingForm} />,
                              )}
                            </Form.Item>
                            <Form.Item label="Quantity Rejected (qtls)">
                              {getFieldDecorator(`orders.${entry.id}.quantityRejectedQuintals`, { initialValue: 0 })(
                                <InputNumber disabled={submittingForm} />,
                              )}
                            </Form.Item>
                            <Form.Item label="Reason for rejection">
                              {getFieldDecorator(`orders.${entry.id}.quantityRejectionReason`)(
                                <Input.TextArea autosize={{ minRows: 2, maxRows: 5 }} disabled={submittingForm} />,
                              )}
                            </Form.Item>
                            <p>
                              <strong>Final Rate (per qtl): </strong>
                              {getCurrencyFormattedValue(
                                entry.invoicedRatePerQuintal -
                                  getFieldValue(`orders.${entry.id}.qualityDiRatePerQuintal`),
                              )}
                            </p>
                            <p>
                              <strong>Final Quantity (qtls): </strong>
                              {getFieldValue(`orders.${entry.id}.quantityDeliveredQuintals`) -
                                getFieldValue(`orders.${entry.id}.quantityRejectedQuintals`)}
                            </p>
                          </Card.Body>
                        </Card>
                        <WhiteSpace />
                      </Fragment>
                    ))}

                    <table id="verifyShipmentPageSummaryTable">
                      <tbody>
                        <tr>
                          <th>Final Total Quantity (qtls)</th>
                          <td style={{ textAlign: 'right' }}>{totalQuantity} qtls</td>
                        </tr>
                        <tr>
                          <th>Invoice Total (Before GST)</th>
                          <td style={{ textAlign: 'right' }}>
                            {getCurrencyFormattedValue(shipment.invoiceTotal - shipment.invoiceGstAmount)}
                          </td>
                        </tr>
                        <tr>
                          <th>Calculated Total Amount</th>
                          <td style={{ textAlign: 'right' }}>{getCurrencyFormattedValue(totalAmount)}</td>
                        </tr>
                        <tr>
                          <th>GST</th>
                          <td style={{ textAlign: 'right' }}>{getCurrencyFormattedValue(shipment.invoiceGstAmount)}</td>
                        </tr>
                        <tr>
                          <th>LORRY H</th>
                          <td>
                            {getFieldDecorator('freightPaidByBuyer', {
                              rules: [{ required: true, message: 'Field is required' }],
                            })(<InputNumber disabled={submittingForm} min={0} style={{ width: '100%' }} required />)}
                          </td>
                        </tr>
                        <tr>
                          <th>Total Payable</th>
                          <td style={{ textAlign: 'right' }}>
                            {getCurrencyFormattedValue(
                              totalAmount + shipment.invoiceGstAmount - (getFieldValue('freightPaidByBuyer') || 0),
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <Button
                      type="primary"
                      onClick={this.handleSubmit}
                      loading={submittingForm}
                      style={{ marginTop: 20 }}
                    >
                      Submit
                    </Button>
                  </Form>
                </Fragment>
              );
            }}
          </Query>
        </div>
      </LocaleProvider>
    );
  }
}

VerifyShipmentPage.propTypes = {
  id: PropTypes.string,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

VerifyShipmentPage.defaultProps = {
  id: null,
};

export default compose(
  graphql(MUTATION),
  Form.create(),
)(VerifyShipmentPage);
