import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { ActivityIndicator } from 'antd-mobile';
import moment from 'moment';
import PaymentCollectionForm from '../forms/PaymentCollectionForm';

import shipmentPaymentDetailsQuery from '../graphql/queries/shipmentPaymentDetailsQuery.graphql';

const ShipmentPaymentCollectionPage = ({ id }) => (
  <Query fetchPolicy="network-only" query={shipmentPaymentDetailsQuery} variables={{ id: parseInt(id, 10) }}>
    {({ loading, error, data }) => {
      if (loading) return <ActivityIndicator />;
      if (error)
        return (
          <p>
            <strong>ERROR: </strong>
            {error.message}
          </p>
        );
      const { shipment } = data;
      const invoiceDateMoment = moment(shipment.invoiceDate, 'YYYY-MM-DD');
      return (
        <div className="contentBoxWithPadding">
          <h2 style={{ padding: '10px', textAlign: 'center' }}>Invoice Payment Collection</h2>
          <h3>
            <strong>Seller: </strong>
            {shipment.seller.name}
          </h3>
          <h3>
            <strong>Invoice: </strong>
            {shipment.invoiceNo}
          </h3>
          <h3>
            <strong>Invoice Date: </strong>
            {invoiceDateMoment.format('DD MMMM, YYYY')}
          </h3>
          <h3 style={{ marginBottom: 20, fontWeight: 700, fontStyle: 'italic' }}>
            {moment().diff(invoiceDateMoment, 'days')} days pending
          </h3>
          <PaymentCollectionForm shipment={shipment} />
        </div>
      );
    }}
  </Query>
);

ShipmentPaymentCollectionPage.propTypes = {
  id: PropTypes.string,
};

ShipmentPaymentCollectionPage.defaultProps = {
  id: null,
};

export default ShipmentPaymentCollectionPage;
