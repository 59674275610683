import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { ActivityIndicator } from 'antd-mobile';
import { Select } from 'antd';

import allBuyerNamesQuery from '../graphql/queries/allBuyerNamesQuery.graphql';
import BuyerOrderForm from '../forms/BuyerOrderForm';

class BuyerOrdersRequirements extends Component {
  state = {
    buyersSelectValue: undefined,
  };

  onPickerOk = (value) => {
    this.setState({ buyersSelectValue: value });
  };

  render() {
    const { buyersSelectValue } = this.state;
    return (
      <div className="contentBox">
        <h2 style={{ padding: '10px', textAlign: 'center' }}>Merchant Orders</h2>
        <Query query={allBuyerNamesQuery} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) return <ActivityIndicator />;
            if (error) return <p>{error.message}</p>;
            const { buyers } = data;
            return (
              <div>
                <h3 style={{ marginLeft: 3 }}>Merchant:</h3>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select merchant"
                  onChange={this.onPickerOk}
                  optionFilterProp="children"
                >
                  {buyers.map((entry) => (
                    <Select.Option value={entry.id} key={entry.id}>
                      {entry.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            );
          }}
        </Query>
        {buyersSelectValue ? <BuyerOrderForm buyerId={buyersSelectValue} key={buyersSelectValue} /> : null}
      </div>
    );
  }
}

export default BuyerOrdersRequirements;
