import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd-mobile';
import { navigate } from '@reach/router';
import withUserRole from '../auth/withUserRole';

const ListItem = List.Item;

const HomePage = ({ userRole }) => (
  <div className="contentBox">
    <h1 style={{ textAlign: 'center' }}>Homepage</h1>
    <List>
      {userRole === 'admin' ? (
        <ListItem arrow="horizontal" onClick={() => navigate('/rates/products')}>
          Daily product rates
        </ListItem>
      ) : null}
      {userRole === 'admin' ? (
        <ListItem arrow="horizontal" onClick={() => navigate('/orders/buyers')}>
          Take orders
        </ListItem>
      ) : null}
      <ListItem arrow="horizontal" onClick={() => navigate('/verification/shipments')}>
        Verify miller deliveries
      </ListItem>
      <ListItem arrow="horizontal" onClick={() => navigate('/buyers/payments/create')}>
        Payment collection from merchant
      </ListItem>
    </List>
  </div>
);

HomePage.propTypes = {
  userRole: PropTypes.string.isRequired,
};

export default withUserRole(HomePage);
