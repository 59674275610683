import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { Form, InputNumber, Input, Checkbox } from 'antd';
import { Button, Toast, List, Picker, DatePicker, LocaleProvider } from 'antd-mobile';
import moment from 'moment';
import enUS from 'antd-mobile/lib/locale-provider/en_US';
import { log, getCurrencyFormattedValue } from '../uitls/globals';

import MUTATION from '../graphql/mutations/createBuyerPaymentShipmentMutation.graphql';

const FormItem = Form.Item;

class PaymentCollectionForm extends Component {
  state = {
    submittingForm: false,
    fullPaymentChecked: false,
  };

  checkboxOnChange = (e, totalPayable) => {
    const { form } = this.props;

    const value = e.target.checked;
    this.setState(
      {
        fullPaymentChecked: value,
      },
      () => {
        if (!value) form.resetFields(['shortAmount', 'sellerComissionDeducted', 'amount']);
        if (value) form.setFieldsValue({ amount: totalPayable });
      },
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submittingForm: true });

    const { form, mutate, shipment } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const valuesToSubmit = { ...values, sellerShipmentId: shipment.id, fullPayment: this.state.fullPaymentChecked };
        // eslint-disable-next-line prefer-destructuring
        valuesToSubmit.paymentMode = values.paymentMode[0];
        valuesToSubmit.chequeDate = moment(valuesToSubmit.chequeDate).format('YYYY-MM-DD');
        mutate({ variables: valuesToSubmit })
          .then(() => {
            Toast.success('Payment recorded!', 1);
            this.setState({ submittingForm: false });
            window.history.back();
          })
          .catch((mutErr) => {
            log(mutErr);
            this.setState({ submittingForm: false });
            Toast.fail(mutErr.message, 3);
          });
      } else {
        this.setState({ submittingForm: false });
      }
    });
  };

  render() {
    const { form, shipment } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { submittingForm, fullPaymentChecked } = this.state;

    const paymentMode = getFieldValue('paymentMode');
    let chequeSelected = false;
    if (Array.isArray(paymentMode) && paymentMode.length > 0 && paymentMode[0] === 'cheque') {
      chequeSelected = true;
    }

    const balanceForDiscount =
      shipment.calculatedTotalAmount - shipment.accounts.totalDiscountAmount - shipment.accounts.totalPaid;

    let discountApplicablePercent = shipment.discountApplicableTodayPercent;
    if (getFieldValue('discountApplicablePercent') >= 0)
      discountApplicablePercent = getFieldValue('discountApplicablePercent');
    const maxDiscountAmount = (discountApplicablePercent / 100) * balanceForDiscount;
    let discountApplicableAmount = maxDiscountAmount;
    const paymentAmount = getFieldValue('amount') || 0;
    if (paymentAmount > 0 && !fullPaymentChecked) {
      const currentDiscountAmount = paymentAmount * (discountApplicablePercent / 100);
      if (currentDiscountAmount < maxDiscountAmount) discountApplicableAmount = currentDiscountAmount;
    }

    const totalPayable =
      balanceForDiscount - maxDiscountAmount - shipment.freightPaidByBuyer + shipment.invoiceGstAmount;

    let balanceAfterPayment =
      totalPayable -
      paymentAmount -
      (getFieldValue('shortAmount') || 0) -
      (getFieldValue('sellerComissionDeducted') || 0);
    if (!fullPaymentChecked && paymentAmount > 0) balanceAfterPayment += maxDiscountAmount - discountApplicableAmount;

    console.log({
      balanceForDiscount,
      discountApplicablePercent,
      discountApplicableAmount,
      paymentAmount,
      maxDiscountAmount,
      totalPayable,
      balanceAfterPayment,
    });

    return (
      <Form layout="vertical">
        <FormItem label="Discount (%)" hasFeedback>
          {getFieldDecorator('discountApplicablePercent', {
            initialValue: shipment.discountApplicableTodayPercent,
            rules: [{ required: true, message: 'Field is required' }],
          })(<InputNumber style={{ width: '100%' }} min={0} disabled={submittingForm} required />)}
        </FormItem>
        <table id="pcf-summaryTable">
          <tbody>
            <tr>
              <th>Balance Amount</th>
              <td>{getCurrencyFormattedValue(balanceForDiscount)}</td>
            </tr>
            <tr>
              <th>
                Discount Applicable <br />
                <i>(on full payment)</i>
              </th>
              <td>{getCurrencyFormattedValue(maxDiscountAmount)}</td>
            </tr>
            <tr>
              <th>Lorry Hire (L.H.)</th>
              <td>{getCurrencyFormattedValue(shipment.freightPaidByBuyer)}</td>
            </tr>
            <tr>
              <th>GST</th>
              <td>{getCurrencyFormattedValue(shipment.invoiceGstAmount)}</td>
            </tr>
            <tr>
              <th style={{ fontSize: '1rem' }}>Total Payable</th>
              <td style={{ fontSize: '1rem' }}>
                <strong>{getCurrencyFormattedValue(totalPayable)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
        <FormItem label="Paying full amount?">
          <Checkbox
            value={fullPaymentChecked}
            onChange={(e) => {
              this.checkboxOnChange(e, totalPayable);
            }}
            style={{
              width: '100%',
              padding: '10px',
            }}
          >
            Select if merchant is paying full amount
          </Checkbox>
        </FormItem>
        <FormItem label="Amount Paid" hasFeedback>
          {getFieldDecorator('amount', {
            initialValue: fullPaymentChecked ? totalPayable : undefined,
            rules: [{ required: true, message: 'Field is required' }],
          })(<InputNumber style={{ width: '100%' }} min={0} disabled={submittingForm} required />)}
        </FormItem>
        <FormItem label="Short Amount" hasFeedback>
          {getFieldDecorator('shortAmount', {
            initialValue: 0,
            rules: [{ required: true, message: 'Field is required' }],
          })(
            <InputNumber style={{ width: '100%' }} min={0} disabled={submittingForm || !fullPaymentChecked} required />,
          )}
        </FormItem>
        <FormItem label="Miller Commission (CASH)" hasFeedback>
          {getFieldDecorator('sellerComissionDeducted', {
            initialValue: 0,
            rules: [{ required: true, message: 'Field is required' }],
          })(
            <InputNumber style={{ width: '100%' }} min={0} disabled={submittingForm || !fullPaymentChecked} required />,
          )}
        </FormItem>
        <h3 style={{ padding: 15, backgroundColor: '#e7f3ff', marginBottom: 20 }}>
          Balance after this payment:{' '}
          {getCurrencyFormattedValue(
            balanceAfterPayment > -0.01 && balanceAfterPayment < 0.01
              ? Math.abs(balanceAfterPayment)
              : balanceAfterPayment,
          )}
        </h3>
        <FormItem label="Payment Mode">
          <List>
            {getFieldDecorator('paymentMode', {
              trigger: 'onOk',
              validateTrigger: 'onOk',
              rules: [{ required: true, message: 'Field is required' }],
            })(
              <Picker
                data={[
                  { label: 'Cheque', value: 'cheque' },
                  { label: 'NEFT', value: 'neft' },
                  { label: 'RTGS', value: 'rtgs' },
                  { label: 'Cash', value: 'cash' },
                ]}
                okText="Select"
                dismissText="Cancel"
                cols={1}
                extra={<span />}
              >
                <List.Item className="selectPickerListItem" arrow="down">
                  Select One
                </List.Item>
              </Picker>,
            )}
          </List>
        </FormItem>
        {chequeSelected ? (
          <Fragment>
            <FormItem label="Cheque No." hasFeedback>
              {getFieldDecorator('chequeNo', {
                rules: [{ required: true, message: 'Field is required' }],
              })(<Input disabled={submittingForm} required />)}
            </FormItem>
            <FormItem label="Cheque Bank Name" hasFeedback>
              {getFieldDecorator('chequeBank', {
                rules: [{ required: true, message: 'Field is required' }],
              })(<Input disabled={submittingForm} required />)}
            </FormItem>
            <FormItem label="Cheque Date">
              <LocaleProvider locale={enUS}>
                <List>
                  {getFieldDecorator('chequeDate', {
                    rules: [{ required: true, message: 'Field is required' }],
                  })(
                    <DatePicker mode="date" disabled={submittingForm} format={(date) => moment(date).format('Do MMM')}>
                      <List.Item className="selectPickerListItem" arrow="down">
                        Select Date
                      </List.Item>
                    </DatePicker>,
                  )}
                </List>
              </LocaleProvider>
            </FormItem>
          </Fragment>
        ) : (
          <FormItem label="Reference No.">
            {getFieldDecorator('referenceNo')(<Input disabled={submittingForm} />)}
          </FormItem>
        )}

        <Button
          type="primary"
          onClick={this.handleSubmit}
          loading={submittingForm}
          disabled={fullPaymentChecked && balanceAfterPayment > 0}
        >
          Submit
        </Button>
      </Form>
    );
  }
}

PaymentCollectionForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
  shipment: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default compose(
  graphql(MUTATION),
  Form.create(),
)(PaymentCollectionForm);
