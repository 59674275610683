import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber, Select, Radio } from 'antd';
import { graphql, compose } from 'react-apollo';
import { ActivityIndicator, Button, Toast } from 'antd-mobile';
import { log } from '../uitls/globals';

import QUERY from '../graphql/queries/getProductsWithRatesQuery.graphql';
import MUTATION from '../graphql/mutations/createOrderMutation.graphql';

const FormItem = Form.Item;

class BuyerOrderForm extends Component {
  state = {
    submittingForm: false,
    productSelectValue: undefined,
    selectedProduct: null,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submittingForm: true });
    const { form, mutate, buyerId } = this.props;
    const { selectedProduct } = this.state;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const valuesToSubmit = {
          buyerId,
          productId: selectedProduct.id,
          sellerId: selectedProduct.seller.id,
          ...values,
        };
        mutate({ variables: valuesToSubmit })
          .then(() => {
            Toast.success('Order created!', 1);
            this.setState({ submittingForm: false, productSelectValue: undefined });
          })
          .catch((mutErr) => {
            log(mutErr);
            this.setState({ submittingForm: false });
            Toast.fail(mutErr.message, 3);
          });
      } else {
        this.setState({ submittingForm: false });
      }
    });
  };

  onChangeProductSelect = (val) => {
    const { products } = this.props.data;
    this.setState({ productSelectValue: val, selectedProduct: products.find((entry) => entry.id === val) });
  };

  render() {
    const { form, data } = this.props;
    const { loading, error } = data;
    if (loading) return <ActivityIndicator />;
    if (error) return <p>{error.message}</p>;
    const { products } = data;
    const { getFieldDecorator } = form;
    const { submittingForm, productSelectValue, selectedProduct } = this.state;

    let rateInitialValue = null;
    if (
      productSelectValue &&
      selectedProduct &&
      Array.isArray(selectedProduct.dailyRates) &&
      selectedProduct.dailyRates.length > 0
    ) {
      rateInitialValue = selectedProduct.dailyRates[0].ratePerQuintal;
    }

    return (
      <div>
        <h3 style={{ marginLeft: 3, marginTop: 20 }}>Product / Brand:</h3>
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder="Select product"
          value={productSelectValue}
          onChange={this.onChangeProductSelect}
          optionFilterProp="children"
        >
          {products.map((entry) => (
            <Select.Option value={entry.id} key={entry.id}>
              {entry.name}
            </Select.Option>
          ))}
        </Select>
        {productSelectValue ? (
          <Form style={{ padding: 20 }}>
            <FormItem label="Rate (per quintal)" hasFeedback>
              {getFieldDecorator('ratePerQuintal', {
                initialValue: rateInitialValue,
                rules: [{ required: true, message: 'Field is required' }],
              })(<InputNumber style={{ width: 150 }} disabled={submittingForm} required />)}
            </FormItem>
            <FormItem label="Quantity (quintals)" hasFeedback>
              {getFieldDecorator('quantityQuintals', {
                rules: [{ required: true, message: 'Field is required' }],
              })(<InputNumber style={{ width: 150 }} disabled={submittingForm} required />)}
            </FormItem>
            <FormItem label="No. of days for loading">
              {getFieldDecorator('urgencyDays', { initialValue: 0 })(
                <InputNumber style={{ width: 150 }} disabled={submittingForm} />,
              )}
            </FormItem>
            <FormItem label="Unloading Point">
              {getFieldDecorator('unloadingPoint', {
                initialValue: 'shop',
                rules: [{ required: true, message: 'Field is required' }],
              })(
                <Radio.Group>
                  <Radio value="shop">Shop</Radio>
                  <Radio value="godown">Godown</Radio>
                </Radio.Group>,
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSubmit} loading={submittingForm}>
              Submit
            </Button>
          </Form>
        ) : null}
      </div>
    );
  }
}

BuyerOrderForm.propTypes = {
  buyerId: PropTypes.number.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any,
    products: PropTypes.array,
  }).isRequired,
};

export default compose(
  graphql(QUERY),
  graphql(MUTATION),
  Form.create(),
)(BuyerOrderForm);
